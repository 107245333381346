import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Icon, Image, Structure, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				kursin.eu – we help no-code developers for free
			</title>
			<meta name={"description"} content={"Non-profit help to no-code and low-code developers"} />
			<meta property={"og:title"} content={"kursin.eu – we help no-code developers for free"} />
			<meta property={"og:description"} content={"Non-profit help to no-code and low-code developers"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6086791d123639001f37d0f1/images/k.png?v=2021-11-25T21:50:52.343Z"} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Box
				display="flex"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Link
					href="#"
					color="--darkL2"
					text-decoration-line="initial"
					font="--headline3"
					padding="15px 0px 15px 0px"
					target="_top"
				>
					kursin.eu
				</Link>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="15px 0px 15px 0px"
				>
					<Link
						href="#about"
						color="--darkL2"
						text-decoration-line="initial"
						font="--lead"
						padding="0px 15px 0px 15px"
					>
						About us
					</Link>
					<Link
						href="#platforms"
						color="--darkL2"
						text-decoration-line="initial"
						font="--lead"
						padding="0px 15px 0px 15px"
					>
						Platforms
					</Link>
					<Link
						href="#contact"
						color="--darkL2"
						text-decoration-line="initial"
						font="--lead"
						padding="0px 15px 0px 15px"
					>
						Contact
					</Link>
				</Box>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						WE ALWAYS DO OUR BEST
					</Text>
					<Text as="h1" font="--headline1" md-font="--headline2" margin="50px 0 10px 0">
						Non-profit help to no-code and low-code developers
					</Text>
					<Text as="h2" font="--headline2" md-font="--headline2" margin="50px 0 10px 0">
						Helping others feels good
					</Text>
				</StackItem>
			</Stack>
			<Link href="#about" color="--dark">
				<Box text-align="center" margin="96px 0 0 0">
					<Text margin="8px 0" text-transform="uppercase" font="--base" color="--lightD2">
						Who we are
					</Text>
					<Icon category="md" margin="0 auto" icon={MdArrowDownward} color="--lightD2" />
				</Box>
			</Link>
		</Section>
		<Structure id="about" cells-number-total="4" cells-number-group="4" padding="80px 0px 80px 0px">
			<Override
				slot="Content"
				grid-template-columns="repeat(2, 6fr)"
				sm-grid-template-columns="12fr"
				lg-display="flex"
				lg-flex-wrap="wrap"
				grid-gap="50px 16px"
			>
				<Override slot="Cell 0th" grid-column="1 / span 2" sm-grid-column="auto" />
				<Override
					slot="Cell 3rd"
					grid-column="1 / span 2"
					sm-grid-column="auto"
					display="grid"
					grid-gap="32px"
					grid-template-columns="repeat(3, 1fr)"
					sm-grid-template-columns="1fr"
					sm-display="block"
					sm-flex="1 1 0%"
				/>
				<Override slot="cell-3">
					<Image
						src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?auto=format&fit=crop&w=400&q=80"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						border-radius="24px"
					/>
					<Image
						src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?auto=format&fit=crop&w=800&q=80"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 2"
						border-radius="24px"
						sm-display="none"
						sm-grid-column="1 / span 1"
					/>
				</Override>
				<Override slot="Cell 0" display="none" />
				<Override slot="cell-2">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL1"
						font="--headline3"
						lg-text-align="center"
						display="flex"
						align-items="flex-start"
					>
						We are a team of no-code and low-code developers that helps other developers in solving simple and complex issues absolutely free of charge.
						<br />
						<br />
						We started helping other developers in our free time and realized that this can be a useful and interesting activity, even if it does not bring any income.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						color="--dark"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						padding="0px 16px 0px 0px"
						margin="0px 0px 0px 0px"
					>
						Our mission is to make no-code developer life easier
					</Text>
				</Override>
				<Override slot="Cell 1st" lg-width="100%" />
				<Override slot="Cell" lg-margin="0px 0px 20px 0px" />
				{"                                                                                            \n    "}
			</Override>
			{"    "}
		</Structure>
		<Structure
			id="platforms"
			background="#EDF2F6"
			padding="80px 0px 80px 0px"
			cells-number-total="4"
			cells-number-group="4"
		>
			<Override slot="Content" grid-template-columns="repeat(3, 3fr)" grid-gap="64px 36px" md-grid-template-columns="1fr">
				<Override slot="Cell 0th" grid-column="1 / 4" />
				<Override
					slot="Cell 0"
					display="flex"
					flex-direction="column"
					align-items="center"
					md-grid-column="auto / auto"
				/>
				<Override slot="cell-0">
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-align="center"
					>
						Platforms where we are the best
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--greyD3"
						md-text-align="center"
						text-align="center"
						max-width="480px"
					>
						At least because our colleagues, whom we help, think so!
					</Text>
				</Override>
				<Override slot="cell-1">
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						{"                "}
						<Image
							src="https://uploads.quarkly.io/6086791d123639001f37d0f1/images/Logo-no-clearspace.png?v=2021-11-25T21:00:56.179Z"
							display="block"
							margin="0px 0px 30px 0px"
							align-self="flex-start"
							width="80%"
							max-width="250px"
						/>
						{"                "}
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Bubble
						</Text>
						{"                "}
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Bubble introduces a new way to build software. It's a no-code tool that lets you build SaaS platforms, marketplaces and CRMs without code.
						</Text>
						{"            "}
					</Box>
				</Override>
				<Override slot="cell-2">
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						{"                "}
						<Image
							src="https://uploads.quarkly.io/6086791d123639001f37d0f1/images/5dbffbe35ad64f67068cdf18_black.png?v=2021-11-25T20:57:42.615Z"
							display="block"
							margin="0px 0px 30px 0px"
							align-self="flex-start"
							width="80%"
							max-width="250px"
						/>
						{"                "}
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							AppGyver
						</Text>
						{"                "}
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							The world's first professional no-code platform, enabling you to build apps for all form factors, including mobile, desktop, browser, TV and others.
						</Text>
						{"            "}
					</Box>
				</Override>
				<Override slot="cell-3">
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						{"                "}
						<Image
							src="https://uploads.quarkly.io/6086791d123639001f37d0f1/images/favicon-270w.png?v=2021-11-25T20:56:13.415Z"
							display="block"
							margin="0px 0px 30px 0px"
							align-self="flex-start"
							max-width="50px"
						/>
						{"                "}
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Quarkly
						</Text>
						{"                "}
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							A unique tool for designers and developers. Quarkly helps you create websites as quickly as a website builder. And as beautifully as a graphic editor.
						</Text>
						{"            "}
					</Box>
				</Override>
				{"                                                    "}
			</Override>
			{"    "}
		</Structure>
		<Structure background="--color-dark" padding="80px 0px 80px 0px" cells-number-total="4">
			<Override
				slot="Content"
				grid-template-columns="2fr 1fr 1fr 1fr"
				grid-gap="32px"
				lg-grid-template-columns="repeat(3, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Override slot="cell-0">
					{"            "}
					<Text margin="0px 0px 24px 0px" font="--headline4" color="#ffffff" lg-text-align="center">
						{"                "}Stats{"\n            "}
					</Text>
					{"            "}
					<Text font="--base" color="--light" lg-text-align="center" margin="0px 0px 0px 0px">
						Some data is impossible to count!
					</Text>
					{"        "}
				</Override>
				<Override slot="cell-1">
					{"            "}
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ffffff" text-align="center">
						100%{"\n            "}
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Happy developers
					</Text>
					{"        "}
				</Override>
				<Override slot="cell-2">
					{"            "}
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ffffff" text-align="center">
						3780+
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Solved problems
					</Text>
					{"        "}
				</Override>
				<Override slot="cell-3">
					{"            "}
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ffffff" text-align="center">
						15+
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Questions per day
					</Text>
					{"        "}
				</Override>
				<Override slot="Cell 0th" justify-self="center" align-self="center" />
				<Override slot="Cell 0" lg-grid-column="1 / span 3" sm-grid-column="auto / auto" />
				{"                                                    "}
			</Override>
			{"    "}
		</Structure>
		<Structure
			id="contact"
			background="--color-light"
			cells-number-total="2"
			cells-number-group="2"
			padding="64px 0px 64px 0px"
		>
			<Override slot="Content" lg-grid-template-columns="1fr">
				<Override slot="cell-1">
					{"            "}
					<Formspree endpoint="xeqpgrlv">
						{"                            "}
					</Formspree>
					{"        "}
					<Components.QuarklycommunityKitNetlifyForm formName="contact">
						<Box
							gap="16px"
							display="grid"
							flex-direction="row"
							flex-wrap="wrap"
							grid-template-columns="repeat(2, 1fr)"
							grid-gap="16px"
						>
							{"                    "}
							<Box sm-width="100%" display="flex" flex-direction="column">
								{"                        "}
								<Text font="--base" margin="0 0 4px 0">
									{"                            "}Name{"\n                        "}
								</Text>
								{"                        "}
								<Input
									width="100%"
									name="name"
									type="text"
									required
									placeholder="John No-Coder"
								/>
								{"                    "}
							</Box>
							{"                    "}
							<Box sm-width="100%" display="flex" flex-direction="column">
								{"                        "}
								<Text font="--base" margin="0 0 4px 0">
									{"                            "}Email{"\n                        "}
								</Text>
								{"                        "}
								<Input
									width="100%"
									type="email"
									name="email"
									required
									placeholder="example@example.org"
								/>
								{"                    "}
							</Box>
							{"                    "}
							<Box display="flex" flex-direction="column" grid-column="1 / span 2">
								{"                        "}
								<Text font="--base" margin="0 0 4px 0">
									{"                            "}Message{"\n                        "}
								</Text>
								{"                        "}
								<Input
									as="textarea"
									rows="4"
									width="100%"
									name="message"
									required
									placeholder="Describe what can we help you with. Please also provide your Telegram contact for faster communication."
								/>
								{"                    "}
							</Box>
							{"                    "}
							<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
								{"                        "}
								<Button>
									{"                            "}Send{"\n                        "}
								</Button>
								{"                    "}
							</Box>
							{"                "}
						</Box>
					</Components.QuarklycommunityKitNetlifyForm>
				</Override>
				<Override slot="cell-0">
					{"            "}
					<Text
						font="--base"
						color="--grey"
						letter-spacing="1px"
						text-transform="uppercase"
						margin="0"
					>
						{"                "}Contact{"\n            "}
					</Text>
					{"            "}
					<Text font="--headline2" max-width="500px" margin="10px 0 0 0" lg-margin="10px 0 16px 0">
						We can help you too!
					</Text>
					{"        "}
					<Text font="--base" color="--greyD3" max-width="80%">
						Just let us know about your problems during development and we will be happy to help you!
					</Text>
				</Override>
				{"                    "}
			</Override>
			{"    "}
		</Structure>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Text margin="0px 0px 0px 0px" color="--lightD2">
				© 2021 kursin.eu
			</Text>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script place={"endOfHead"} rawKey={"619ff67976da98ae2bd8d82e"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-WZN4GKH');"}
			</script>
			<script place={"endOfBody"} rawKey={"619ff6910c47c956e81fb597"}>
				{"<noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WZN4GKH\"\nheight=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>"}
			</script>
		</RawHtml>
	</Theme>;
});
